import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import EmailInput from "src/components/marketing/email-input"
import GetStartedSection from "src/components/marketing/get-started-section"
import TeamSection from "src/components/marketing/team-section"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import SignatureSection from "src/components/marketing/signature-section"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"
import DemoVideoSection from "src/components/marketing/demo-video-section"
import WorkshopsSection from "src/components/marketing/workshops-section"
import "src/styles/pages/marketing-page.scss"

const REGISTRATION_CODE = process.env.GATSBY_SPARKASSE_LEIPZIG_REGISTRATION_CODE
const WORKSHOPS = [
  {
    date: "07.10.2020",
    time: "15.00-17.00 Uhr",
    name: "Methoden zur konstruktiven Stressbewältigung",
    location: "Online Seminar",
  },
  {},
  {},
  {},
]

export default function DRKLandingPage() {
  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/marketing/Image 4.png",
          "/assets/img/marketing/Group 1960@2x.png",
          "/assets/img/marketing/mindance_anmeldung.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title: "Ihre Reise zu mehr Ruhe und Entspannung mit Mindance",
          description:
            "Mitarbeitende der Stadt- und Kreissparkasse Leipzig erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail geschäftlich"
          registrationCode={REGISTRATION_CODE}
        />
      </Jumbotron>
      <SEO
        title="Sparkasse Leipzig"
        description="Mitarbeitende der Stadt- und Kreissparkasse Leipzig erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Stress reduzieren und Wohlbefinden steigern</h1>
          <p>
            Der Arbeitsalltag – egal, ob im Büro oder in der Filiale – kann
            einen schnell an die eigenen Grenzen bringen. Stress, Anspannung
            oder Frustration sind nicht selten die Folge. Mit Mindance haben Sie
            ab sofort die Möglichkeit, Entspannungsübungen und Meditationen in
            Ihren Alltag einzubauen, um Stress zu reduzieren, Schlafprobleme zu
            überwinden und Ihr Wohlbefinden zu verbessern.
          </p>
          <br />
          <p>
            <b>
              Durch die Kooperation mit der AOK PLUS steht Mindance allen
              Mitarbeitenden der Sparkasse Leipzig kostenfrei zur Verfügung.
            </b>
          </p>
          <br />
          <p>
            Mindance ist ein Leipziger Unternehmen, das sich der Mission
            verschrieben hat, zu einer Welt beizutragen, in der mentale
            Gesundheit denselben Stellenwert hat wie körperliche Gesundheit und
            in der arbeitsbedingte psychische Erkrankungen der Vergangenheit
            angehören. Alle Sparkasse Leipzig Mitarbeiter*innen erhalten
            kostenlosen Zugriff auf das Angebot von Mindance.
            <br />
            <br />
            <b className="green-text text-l">
              Das ist die Chance für Sie, Ihre psychische Gesundheit nachhaltig
              zu fördern!
            </b>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection
        titles={[
          "Checken Sie Ihre mentale Gesundheit und erhalten Sie Ihren persönlichen Übungsplan",
          "Entdecken Sie die große Auswahl an Übungen und Kursen",
          "Verfolgen Sie Fortschritte auf einen Blick",
        ]}
        descriptions={[
          "Starten Sie direkt mit unserem Fragebogen, der Ihre psychische Gesundheit detailliert erfasst. Die Ergebnisse zeigen auf, in welchen Bereichen Ihre mentale Gesundheit einer Förderung bedarf. Auf Ihrem persönlichen Ergebnis basierend erhalten Sie einen Plan mit verschiedenen Übungen. Zum Schluss bestimmen Sie selbst, wie viel Zeit Sie pro Woche investieren möchten. Ob einmal wöchentlich 5 Minuten oder täglich 20 Minuten - Sie haben die Wahl",
          "In der App finden Sie ein 8-wöchiges Grundlagentraining, das Sie dabei unterstützen kann, Ihre eigenen Gedanken und Gefühle besser zu verstehen. Außerdem können Sie nach Belieben aus Übungen zu verschiedenen Themenbereichen, wie “Stress reduzieren”, “Beziehungen stärken”, “Besser schlafen” oder “Achtsamkeit kultivieren” wählen und zwischen 5 und 15 Minuten die Übungslänge einstellen, die für Sie in dem Moment ideal ist.",
          "In Ihrem Profil finden Sie die Timeline Ihrer absolvierten Übungen, Statistiken zu Ihrem Training mit Mindance und die Ergebnisse des Fragebogens. Unser Tipp: Regelmäßiges Üben macht den/die Meister*in, damit ist Regelmäßigkeit erst einmal wichtiger als langes Üben.",
        ]}
      >
        <DemoVideoSection
          title="Sie sind gespannt, was Sie in der Mindance App erwartet? Dann können sie in diesem Video direkt die erste Übung ausprobieren."
          description="Setzen Sie sich dazu an einen Ort, an dem Sie 5 Minuten ungestört sind und folgen Sie den Anweisungen des Sprechers, der Sie durch den Body Scan leiten wird. "
        />
      </MindanceAppSection>
      <TeamSection>
        <h1>Psychologische Beratung über die Chatfunktion oder Telefonate</h1>
        <br />
        <p>
          Sie haben Fragen zum Etablieren einer Routine, möchten konkrete Ziele
          des Trainings gemeinsam ausloten und das Mindance Programm genauer
          kennen lernen? Oder möchten Sie über persönliche Herausforderungen mit
          einer außenstehenden Person sprechen? Dann schreiben Sie unseren
          psychologischen Berater*innen entweder über den Chat in der App oder
          vereinbaren Sie dort ein Telefonat mit ihnen.
        </p>
        <br />
        <p>
          Unsere beratenden Psychologinnen und Psychologen unterliegen der
          gesetzlichen Schweigepflicht - somit werden alle Daten, die uns in
          diesem Rahmen erreichen, streng vertraulich behandelt und unter keinen
          Umständen an Krankenkassen, Ihren Arbeitgeber oder Drittanbieter
          weitergeleitet.
        </p>
      </TeamSection>
      <WorkshopsSection workshops={WORKSHOPS}>
        <h1>Passende Workshops und Online-Seminare zu spannenden Themen</h1>
        <br />
        <p>
          Sie möchten noch mehr über die Themen Stress, Achtsamkeit,
          Spitzenleistung, Schlaf, Entspannung und Mitgefühl erfahren? Dann
          melden Sie sich zu einem der Präsenzworkshops oder Online-Seminare an!
          Das geht ganz einfach direkt in der Mindance App unter Workshops.
        </p>
      </WorkshopsSection>
      <GetStartedSection
        steps={[
          "Laden Sie sich die Mindance App im AppStore/Google PlayStore und dem Stichwort “Mindance” herunter.",
          "Geben Sie hier Ihre E-Mail Adresse ein, um einen Aktivierungscode zu erhalten.",
          "Erstellen Sie sich unter Verwendung Ihres Aktivierungscodes Ihr persönliches, anonymes Profil.",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
